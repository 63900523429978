<template>
  <div class="dashboard">
    <dashboard-info-block />
    <dashboard-charts />
  </div>
</template>

<script>
import DashboardCharts from './DashboardCharts'
import DashboardInfoBlock from './DashboardInfoBlock'

export default {
  name: 'dashboard',
  components: {
    DashboardCharts,
    DashboardInfoBlock,
  },
  created () {
    if (localStorage.getItem('isLoggeIn') === 'yes') {
      localStorage.setItem('isLoggeIn', 'no')
      window.location.reload()
    }
  },
  methods: {
    addAddressToMap ({ city, country }) {
      this.$refs.dashboardMap.addAddress({ city: city.text, country })
    },
  },
}
</script>

<style lang="scss">
  .row-equal .flex {
    .va-card {
      height: 100%;
    }
  }

  .dashboard {
    .va-card {
      margin-bottom: 0 !important;
    }
  }
</style>
