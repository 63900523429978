<template>
  <v-app>
    <div class="row row-equal">
      <div class="flex xs12 md6 xl6">
        <va-card :title="'Marks Coverage'">
          <va-button
            icon="fa fa-print"
            flat
            slot="actions"
            class="mr-0"
            @click="printChart"
          />
          <va-chart class="chart chart--donut" :data="donutChartData" type="donut"/>
        </va-card>
      </div>
      <div class="flex xs12 md6 xl6" v-if="userType === '0'">
        <v-card>
          <v-card-title>
            Pending Approval
            <v-spacer></v-spacer>
            <span>
              <v-text-field outlined dense
                style="width: 200px; position: absolute; left: 180px;top: 15px;"
                placeholder="search by name"
                @keyup.enter="fetchSearchedStudents"
                v-model="search"
                rounded
              ></v-text-field>
              <v-chip color="red" style="cursor: pointer;">{{number.counter}}</v-chip>&nbsp;
              <v-btn color="blue" small style="text-transform: none">View Others</v-btn>
            </span>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="pendingStudents"
            :options.sync="options"
            :server-items-length="totalDesserts"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:item.id="{item}">
              <v-chip small :color="`#9c9c9c`" style="color: white; font-weight: 600; width: 25px; padding: 8px;">
                {{pendingStudents.map(function(x) {return x.id; }).indexOf(item.id)+1}}
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn small color="blue" style="text-transform: none; color: #ffffff; margin-top: 10px !important;">
                <router-link
                  :to="{
                    name:'single-student',
                    params:{
                      student_id:item.id
                    }
                  }"
                  style="color: white"
                >
                  View More..
                </router-link>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <div class="flex xs12 md12 xl12">
        <va-card :title="'Students Registration Report'">
          <va-button
            small
            slot="actions"
            color="danger"
            class="mr-0"
            @click="deleteSection"
            :disabled="lineChartData.labels.length < 2"
            v-if="type ===2"
          >
            <span>{{status}}</span>
          </va-button>
          <va-chart class="chart" ref="lineChart" :data="lineChartData" type="line"/>
        </va-card>
      </div>
      <v-app>
        <v-dialog
          v-model="loginModal"
          max-width="500px"
          :persistent="true"
        >
          <v-card>
            <v-card-title>
              <span class="headline">Change Password</span>
            </v-card-title>
            <b-container>
              <v-row>
                <input type="hidden" v-model="id">
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="password"
                    label="New password"
                    name="Password"
                    type="password"
                    v-validate="'required'"
                    outlined
                    dense
                  ></v-text-field>
                  <span style="color: red;"
                    v-show="errors.has('Password')">{{ errors.first('Password') }}</span>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-model="confirmpassword"
                    label="confirm password"
                    name="confirm password"
                    type="password"
                    v-validate="'required'"
                    outlined
                    dense
                  ></v-text-field>
                  <span style="color: red;"
                    v-show="errors.has('confirm password')">{{ errors.first('confirm password') }}</span>
                </v-col>
              </v-row>
            </b-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="change_password"
                style="text-transform: none;"
                outlined
              >
                Change
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-snackbar
          :timeout="2000"
          :value="true"
          absolute
          top
          color="success"
          right
          text
          v-if="appear"
        >
          Password Changed
        </v-snackbar>
      </v-app>
    </div>
  </v-app>
</template>

<script>
import { getDonutChartData } from '../../data/charts/DonutChartData'
import { getLineChartData } from '../../data/charts/LineChartData'
import DashboardContributorsChart from './DashboardContributorsList'
import Swal from 'sweetalert2'
export default {
  name: 'dashboard-charts',
  components: { DashboardContributorsChart },
  data () {
    return {
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Names', value: 'names' },
        { text: 'Address', value: 'address' },
        { text: 'Email', value: 'email' },
        { text: 'Sex', value: 'sex' },
        { text: '', value: 'actions' },
      ],
      lineChartData: getLineChartData(this.$themes),
      donutChartData: getDonutChartData(this.$themes),
      lineChartFirstMonthIndex: 0,
      students: [],
      type: '',
      status: '',
      loginModal: false,
      password: '',
      confirmpassword: '',
      id: '',
      show: true,
      appear: false,
      display: '',
      user: '',
      pendingStudents: [],
      number: [],
      totalDesserts: 0,
      options: {},
      search: '',
      userType: '',
    }
  },
  created () {
    const user = localStorage.getItem('logged_user')
    if (user) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
    this.userType = this.logger.type
    const token = this.logger.accessToken
    if (!token) {
      this.$router.push({ name: 'login-page' })
    }
    this.modal()
    this.fetchStudents()
    this.fakeApiCall()
  },
  watch: {
    '$themes.primary' () {
      this.lineChartData = getLineChartData(this.$themes)
      this.donutChartData = getDonutChartData(this.$themes)
    },

    '$themes.info' () {
      this.lineChartData = getLineChartData(this.$themes)
      this.donutChartData = getDonutChartData(this.$themes)
    },

    '$themes.danger' () {
      this.donutChartData = getDonutChartData(this.$themes)
    },
    options: {
      handler () {
        this.fakeApiCall()
      },
      deep: true,
    },
  },
  computed: {
    donutChartDataURL () {
      return document.querySelector('.chart--donut canvas').toDataURL('image/png')
    },
  },
  methods: {
    async fetchSearchedStudents (d) {
      const prefix = this.search
      if (prefix.length >= 3) {
        if (localStorage.getItem('logged_user')) {
          this.logger = JSON.parse(localStorage.getItem('logged_user'))
        }
        this.header = this.logger.accessToken
        try {
          const res = await this.axios({
            url: 'search_pending_students',
            data: {
              filter: this.filter,
              item: this.toFilter,
              prefix: this.search,
            },
            method: 'post',
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.search = ''
            this.pendingStudents = res.data
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'login-page' })
          } else if (error.response && error.response.status === 400) {
            this.pendingStudents = []
          } else {
            console.log(error)
          }
          this.search = ''
        }
      }
    },
    fakeApiCall () {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        let items = this.fetchPendingStudents((page - 1) * itemsPerPage, itemsPerPage)
        const total = this.students.length
        if (sortBy.length === true && sortDesc.length === true) {
          items = items.sort((a, b) => {
            const sortA = a[sortBy[0]]
            const sortB = b[sortBy[0]]
            if (sortDesc[0]) {
              if (sortA < sortB) return 1
              if (sortA > sortB) return -1
              return 0
            } else {
              if (sortA < sortB) return -1
              if (sortA > sortB) return 1
              return 0
            }
          })
        }
        if (itemsPerPage > 0) {
          this.pendingStudents = this.pendingStudents.slice((page - 1) * itemsPerPage, page * itemsPerPage)
        }
        setTimeout(() => {
          resolve({
            items,
            total,
          })
        }, 1000)
      })
    },
    modal () {
      const user = localStorage.getItem('logged_user')
      const dash = JSON.parse(user)
      if (dash.status === '2') {
        this.loginModal = true
        this.id = dash.uid
        this.user = dash.type
      }
    },
    async change_password () {
      if (this.password !== this.confirmpassword) {
        Swal.fire({
          title: 'Error!',
          text: 'Password not match',
          icon: 'warning',
          confirmButtonText: 'Ok',
        })
      } else {
        if (localStorage.getItem('logged_user')) {
          this.logger = JSON.parse(localStorage.getItem('logged_user'))
        }
        this.header = this.logger.accessToken
        const data = {
          id: this.id,
          password: this.password,
          user: this.user,
        }
        try {
          const res = await this.axios({
            url: 'change_password',
            method: 'post',
            data: data,
            headers: {
              Authorization: 'Bearer ' + this.header,
            },
          })
          if (res.status === 200) {
            this.appear = true
            this.loginModal = false
            this.password = ''
            this.comfirmpassword = ''
            setTimeout(() => {
              this.$router.push({ name: 'login-page' })
            }, 2000)
          } else {
            alert('Data not saved')
          }
        } catch (e) {
          if (e.response && e.response.status === 401) {
            this.$router.push({ name: 'login-page' })
          } else if (e.response && e.response.status === 403) {
            this.names = ''
            this.selected = ''
          } else {
            console.log(e)
          }
        }
      }
    },
    async fetchStudents () {
      const user = localStorage.getItem('logged_user')
      const dash = JSON.parse(user)
      if (user) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_students_status/' + dash.uid,
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          localStorage.setItem('user_status', JSON.stringify(res.data.status))
          this.students = res.data
          if (res.data.status === '1' && res.data.type === '1') {
            this.status = 'Pending'
            this.type = 2
          } else if (res.data.status === '2' && res.data.type === '1') {
            this.status = 'Approved'
            this.type = 2
          } else if (res.data.status === '3' && res.data.type === '1') {
            this.status = 'Rejected'
            this.type = 2
          } else if (res.data.status === '4' && res.data.type === '1') {
            this.status = 'Not yet Registered'
            this.type = 2
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    deleteSection () {
      this.lineChartFirstMonthIndex += 1
      this.lineChartData = getLineChartData(this.$themes, this.lineChartFirstMonthIndex)
      this.$refs.lineChart.$refs.chart.refresh()
    },
    printChart () {
      const win = window.open('', 'Print', 'height=600,width=800')
      win.document.write(`<br><img src='${this.donutChartDataURL}'/>`)
      // TODO: find better solution how to remove timeout
      setTimeout(() => {
        win.document.close()
        win.focus()
        win.print()
        win.close()
      }, 200)
    },
    fetchPendingStudents (start, end) {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      this.axios({
        url: 'get_pending_students/' + start + '/' + end,
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + this.header,
        },
      })
        .then((response) => {
          this.loading = false
          this.pendingStudents = response.data.students
          this.number = response.data.number
          this.totalDesserts = parseInt(response.data.number)
          return response.data
        })
        .catch(() => {
          this.loading = false
          this.pendingStudents = []
        })
    },
  },
}
</script>

<style scoped>
  .chart {
    height: 400px;
  }
</style>
